import { useState, useEffect, createContext, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header/Header';
import ItemList from './components/ItemList/ItemList';
import Conditions from './pages/Conditions/Conditions';
import Contacts from './pages/Contacts/Contacts';
import Divider from './components/Divider/Divider';
import PromoBanner from './components/PromoBanner/PromoBanner';
import About from './pages/About/About';
import NotFound from './pages/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import ItemView from './components/ItemView/ItemView';
import ScrollToTop from './components/SrollToTop/ScrollToTop';
import './App.css';

export const ItemsDataContext = createContext();

const WithHeader = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <PromoBanner />
      <Divider />
      {children}
    </>
  );
};

function App() {
  const [itemsData, setData] = useState(null);
  const [test, setTest] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // For server path
        const response = await axios.get('data/items.json');

        // For localhost path
        // const response = await axios.get('/transeita-tools/data/items.json');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const contextData = useMemo(
    () => ({
      itemsData,
      test,
      setTest,
    }),
    [itemsData, test, setTest]
  );

  console.log('itemsData ===', itemsData);

  return (
    <ItemsDataContext.Provider value={contextData}>
      <Routes>
        <Route
          path="/"
          element={
            <WithHeader>
              <ItemList />
            </WithHeader>
          }
        />
        <Route
          path="/apie-mus"
          element={
            <WithHeader>
              <About />
            </WithHeader>
          }
        />
        <Route
          path="/salygos"
          element={
            <WithHeader>
              <Conditions />
            </WithHeader>
          }
        />
        <Route
          path="/kontaktai"
          element={
            <WithHeader>
              <Contacts />
            </WithHeader>
          }
        />
        <Route
          path="/:category"
          element={
            <WithHeader>
              <ItemList />
            </WithHeader>
          }
          exact
        />
        <Route
          path="/:category/:itemName"
          element={
            <WithHeader>
              <ItemView />
            </WithHeader>
          }
        />
        <Route
          path="*"
          element={
            <WithHeader>
              <NotFound />
            </WithHeader>
          }
        />
      </Routes>
      <Footer />
    </ItemsDataContext.Provider>
  );
}

export default App;
