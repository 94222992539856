import styles from './Search.module.scss';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { ItemsDataContext } from '../../App';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

function Search() {
  const { itemsData } = useContext(ItemsDataContext);

  const items = itemsData;
  const navigate = useNavigate();

  const handleOnSelect = (itemsData) => {
    navigate(`/${itemsData?.catId}/${itemsData?.id}`);
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  return (
    <div className={styles.searchBar}>
      {itemsData && (
        <ReactSearchAutocomplete
          items={items}
          onSelect={handleOnSelect}
          autoFocus
          formatResult={formatResult}
          placeholder="Įrankio pavadinimas"
          styling={{
            placeholderColor: '#838d99',
            fontFamily: 'open sans',
            fontSize: '0.9rem',
          }}
        />
      )}
    </div>
  );
}

export default Search;
