import styles from './NotFound.module.scss';

function NotFound() {
  return (
    <div className={styles.container}>
      <div>
        <h2>Ieškomas puslapis nerastas</h2>
        <p>Atsiprašome, puslapis, kurį bandote atidaryti, neegzistuoja.</p>
      </div>
    </div>
  );
}

export default NotFound;
