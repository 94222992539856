import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Navigation from '../Navigation/Navigation';
import logoImg from '../../assets/img/logo.png';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import styles from './Drawer.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import Categories from '../Categories/Categories';
import { useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ItemsDataContext } from '../../App';

export default function TemporaryDrawer({ isOpen, setOpen }) {
  function closeDrawer() {
    setOpen(false);
  }

  const { itemsData } = useContext(ItemsDataContext);
  const { category: selectedCategory } = useParams('category');

  const item = useMemo(
    () => itemsData?.find((item) => item.catId === selectedCategory),
    [selectedCategory, itemsData]
  );

  const navigate = useNavigate();

  const setSelectedCategory = (catId) =>
    catId ? navigate(`/${catId}`) : navigate('/');

  const DrawerList = (
    <Box
      sx={{
        width: { xs: '100vw', md: '40vw' },
        justifyContent: 'center',
        alignItems: 'left',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '20px',
      }}
      role="presentation"
    >
      <CloseIcon
        sx={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }}
        onClick={closeDrawer}
      />
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Link to="/">
          <img src={logoImg} alt="Logo" width="250px" />
        </Link>
      </div>
      <Divider
        sx={{ height: '1px', backgroundColor: '#f4f4f4', width: '100%', mt: 2 }}
      />
      <Navigation closeDrawer={closeDrawer} />
      <Divider
        sx={{ height: '1px', backgroundColor: '#f4f4f4', width: '100%', mt: 2 }}
      />
      <Categories
        onSelectCategory={setSelectedCategory}
        item={item}
        closeDrawer={closeDrawer}
        isDrawer
      />
      <Divider
        sx={{
          height: '1px',
          backgroundColor: '#f4f4f4',
          width: '100%',
          mt: 2,
        }}
      />
      <div className={styles.contacts}>
        <h3>UAB "Transeita"</h3>
        <div className={styles.line}>
          <HomeIcon style={{ fontSize: 20 }} /> J. Sniadeckio g. 2 Šalčininkai
        </div>
        <div className={styles.line}>
          <PhoneIcon style={{ fontSize: 20 }} />
          <a href="tel:+37069211117">+370 692 111 17</a>
        </div>
        <div className={styles.line}>
          <PhoneIcon style={{ fontSize: 20 }} />
          <a href="tel:+37060582344">+370 605 823 44</a>
        </div>
        <div className={styles.line}>
          <EmailIcon style={{ fontSize: 20, marginTop: '4px' }} />
          <a href="mailto:statyba@transeita.com">statyba@transeita.com</a>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={() => setOpen(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
