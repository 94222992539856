import React from 'react';
import styles from './Divider.module.scss';

function Divider() {
  return (
    <div className={styles.categoryBar}>
      <div className={styles.categoryBarWrap}>
        {/* <p>
          Sukimo ir montavimo įranga / Akumuliatorinis suktukas Makita CT-4356
        </p> */}
      </div>
    </div>
  );
}

export default Divider;
