import Navigation from '../Navigation/Navigation';
import Search from '../Search/Search';
import styles from './Header.module.scss';
import { useState, useEffect } from 'react';
import logoImg from '../../assets/img/logo.png';
import { Link } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
import TemporaryDrawer from '../Drawer/Drawer';

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.header} ${scrolled ? styles.headerScrolled : ''}`}
      >
        <div>
          <Link to="/">
            <img src={logoImg} alt="Logo" width="250px" />
          </Link>
        </div>
        <Search />
        <div>
          <Navigation hideOnSmallScreens />
        </div>
        <div className={styles.hamburger}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
      </div>
      <TemporaryDrawer isOpen={isOpen} setOpen={setOpen} />
    </>
  );
}

export default Header;
