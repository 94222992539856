import { Container, Typography, Divider, Box } from '@mui/material';
import AddressMap from '../../components/AddressMap/AddressMap';
import styles from './Contacts.module.scss';

function Contacts() {
  return (
    <Container>
      <Box
        sx={{
          padding: '90px 0',
          maxWidth: '900px',
          height: 'auto',
          margin: '0 auto',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ fontWeight: 'bold', color: '#213F5C', pb: 1 }}
        >
          Kontaktai
        </Typography>
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ flex: '1 1 100%', marginBottom: '20px' }}>
            <Typography
              variant="body2"
              sx={{ fontSize: '15px', color: '#464646', lineHeight: '1.7rem' }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                UAB "Transeita"
              </Typography>
              <br />
              P. Vileišio g. 18, Vilnius, Lietuva,
              <br />
              Korespondencija: J. Sniadeckio g. 2 Šalčininkai
              <br />
              Direktorius: +(370) 606 10 062, +(370) 692 10 564
              <br />
              El.paštas: statyba@transeita.com
            </Typography>
          </div>
          <div style={{ flex: '1 1 100%' }}>
            <AddressMap />
          </div>
        </div>
        <div className={styles.Contacts}></div>
      </Box>
    </Container>
  );
}

export default Contacts;
