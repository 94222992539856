import styles from './Loading.module.scss';

function Loading() {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading}>
        <img
          src={require('../../assets/svg/loading.svg').default}
          alt="mySvgImage"
        />
      </div>
    </div>
  );
}

export default Loading;
