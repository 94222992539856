import styles from './ItemView.module.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ItemsDataContext } from '../../App';
import { useContext } from 'react';
import Loading from '../Loading/Loading';

function ItemView() {
  const { itemsData } = useContext(ItemsDataContext);
  const { itemName } = useParams();
  const { category } = useParams();

  if (!itemsData) {
    return <Loading />;
  }

  const filteredItem = itemsData.find((item) => {
    return +itemName === item.id;
  });

  const price = +filteredItem.price;
  const pvm = (price * 21) / 100;
  const priceWithVAT = price + pvm;
  console.log('filteredItem.picture ===', filteredItem.picture);

  return (
    <div className={styles.container}>
      <div className={styles.backArrowDesk}>
        <Link to={`/${category}`}>
          <KeyboardBackspaceIcon
            sx={{ fontSize: '2.5rem', cursor: 'pointer' }}
          />
        </Link>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.backArrow}>
          <Link to={`/${category}`}>
            <KeyboardBackspaceIcon
              sx={{ fontSize: '2rem', cursor: 'pointer' }}
            />
          </Link>
        </div>

        <div className={styles.itemImage}>
          <img src={filteredItem.picture} alt={filteredItem.name} />
        </div>
        <div className={styles.itemDescription}>
          <h2>{filteredItem.name}</h2>
          <Divider />
          <div>
            <p>{filteredItem.description}</p>
          </div>
          <div>
            <p>
              <span>{price} € </span>/1d (su PVM 21%:
              {priceWithVAT.toFixed(2)} €)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemView;
