import styles from './Categories.module.scss';
import { useContext } from 'react';
import { ItemsDataContext } from '../../App';
import { uniqBy } from 'lodash';

function Categories({ onSelectCategory, item, closeDrawer, isDrawer }) {
  const { itemsData } = useContext(ItemsDataContext);

  const categoryCounts = itemsData?.reduce((counts, catItem) => {
    counts[catItem?.cat] = (counts[catItem?.cat] || 0) + 1;
    return counts;
  }, {});

  const repeatedCategories = uniqBy(itemsData, 'catId');

  const totalItemCount = Object.values(categoryCounts).reduce(
    (total, count) => total + count,
    0
  );

  const handleClick = (category) => {
    onSelectCategory(category);
    closeDrawer();
  };

  return (
    <div
      className={styles.wrapper}
      style={{ minHeight: isDrawer ? 'auto' : '100%' }}
    >
      <div className={styles.catList}>
        <h4>Įrankių kategorijos</h4>
        <p
          onClick={() => handleClick(null)}
          className={!!item?.catId ? '' : styles.boldCategory}
        >
          Visi Įrankiai ({totalItemCount})
        </p>
        {repeatedCategories.map((repeatedItem) => (
          <p
            key={repeatedItem?.id}
            onClick={() => handleClick(repeatedItem.catId)}
            className={
              item?.catId === repeatedItem.catId ? styles.boldCategory : ''
            }
          >
            {repeatedItem?.cat} ({categoryCounts[repeatedItem.cat]})
          </p>
        ))}
      </div>
    </div>
  );
}

export default Categories;
