import styles from './PromoBanner.module.scss';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

function PromoBanner() {
  return (
    <div className={styles.promoBanner}>
      <div className={styles.banner}>
        <div className={styles.textLeft}>
          Visiems įrankiams
          <br />
          nuolaida <span>15%</span>
          <p>Nuolaidos kodas Promo1</p>
        </div>
        <div className={styles.textRight}>
          <div className={styles.gap}>
            <div className={styles.line}>
              <QueryBuilderIcon /> I-V 8:00 - 17:00VI - VII Nedirbame
            </div>
            <div className={styles.line}>
              <HomeIcon /> J. Sniadeckio g. 2 Šalčininkai
            </div>
          </div>
          <div className={styles.tel}>
            <div className={styles.line}>
              <PhoneIcon />
              <a href="tel:+37069211117">+370 692 111 17</a>
            </div>
            <div className={styles.line}>
              <PhoneIcon />
              <a href="tel:+37060582344">+370 605 823 44</a>
            </div>
          </div>
          <div className={styles.line}>
            <EmailIcon sx={{ mt: '4px' }} />
            <a href="mailto:statyba@transeita.com">statyba@transeita.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoBanner;
