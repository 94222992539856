import { Container, Typography, Divider, Box } from '@mui/material';

function About() {
  return (
    <Container>
      <Box
        sx={{
          padding: '70px 0',
          maxWidth: '900px',
          height: 'auto',
          margin: '0 auto',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ fontWeight: 'bold', color: '#213F5C', pb: 1 }}
        >
          Apie mus
        </Typography>
        <Divider />
        <Typography sx={{ mt: 3, lineHeight: '1.8rem', color: '#5d656b' }}>
          "Transeita Tools" yra naujas įmonės "Transeita" padalinys, skirtas
          įrankių nuomai. Mes esame patikima ir profesionali įmonė, siūlanti
          aukštos kokybės įrankius tiek profesionaliems rangovams, tiek
          mėgėjams. Mūsų misija – suteikti jums prieinamą ir patogią galimybę
          išsinuomoti reikiamus įrankius, kad jūsų projektai būtų vykdomi
          sklandžiai ir efektyviai. Su daugiau nei 20 metų patirtimi
          transportavimo ir logistikos srityse, "Transeita" sukūrė stiprius
          ryšius su partneriais visoje Europoje ir Rusijoje. Šis pasitikėjimas
          ir profesionalumas persikėlė į naują įmonės įrankių nuomos sritį. Mūsų
          komanda yra pasiruošusi suteikti jums išskirtinį aptarnavimą, padedant
          išsirinkti tinkamus įrankius jūsų projektui. Mūsų darbuotojai kalba
          anglų, vokiečių, rusų, lenkų ir lietuvių kalbomis, todėl bendravimas
          su mūsų klientais ir partneriais yra lengvas ir sklandus. "Transeita
          Tools Rental" siekia suteikti lanksčias nuomos galimybes, kad
          galėtumėte rasti reikiamus įrankius bet kokiam projektui. Mes siūlome
          platų įrankių asortimentą, įskaitant elektrinius įrankius, statybos
          įrangą, sodo techniką ir dar daugiau. Mūsų tikslas – suteikti jums
          aukščiausios kokybės įrankius už konkurencingas kainas, kad galėtumėte
          vykdyti savo darbus be rūpesčių.
        </Typography>
      </Box>
    </Container>
  );
}

export default About;
