import styles from './Footer.module.scss';
import logoImg from '../../assets/img/logo-footer.png';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

function Footer() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <div>
            <Link to="/">
              <img src={logoImg} alt="Logo" width="250px" />
            </Link>
          </div>
          <p>© 2024 Visos teisės saugomos</p>
        </div>
        <div className={styles.contacts}>
          <h3>UAB "Transeita"</h3>
          <div className={styles.line}>
            <HomeIcon style={{ fontSize: 20 }} /> J. Sniadeckio g. 2 Šalčininkai
          </div>
          <div className={styles.line}>
            <PhoneIcon style={{ fontSize: 20 }} />
            <a href="tel:+37069211117">+370 692 111 17</a>
          </div>
          <div className={styles.line}>
            <PhoneIcon style={{ fontSize: 20 }} />
            <a href="tel:+37060582344">+370 605 823 44</a>
          </div>
          <div className={styles.line}>
            <EmailIcon style={{ fontSize: 20, marginTop: '4px' }} />
            <a href="mailto:statyba@transeita.com">statyba@transeita.com</a>
          </div>
        </div>
        <div className={styles.navigation}>
          <nav>
            <ul>
              <li>
                <Link to="/apie-mus">Apie mus</Link>
              </li>
              <li>
                <Link to="/">Įrankiai</Link>
              </li>
              <li>
                <Link to="/salygos">Nuomos sąlygos</Link>
              </li>
              <li>
                <Link to="/kontaktai">Kontaktai</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Footer;
