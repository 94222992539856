import { useContext, useMemo } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import { ItemsDataContext } from '../../App';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './ItemList.module.scss';
import CategoryTitle from '../CategoryTitle/CategoryTitle';
import Categories from '../Categories/Categories';
import Loading from '../Loading/Loading';

function ItemList() {
  const { itemsData } = useContext(ItemsDataContext);
  const navigate = useNavigate();

  const { category: selectedCategory } = useParams('category');

  const item = useMemo(
    () => itemsData?.find((item) => item.catId === selectedCategory),
    [selectedCategory, itemsData]
  );

  const setSelectedCategory = (catId) =>
    catId ? navigate(`/${catId}`) : navigate('/');

  if (!itemsData) {
    return <Loading />;
  }
  return (
    <div className={styles.container}>
      <Box sx={{ display: 'flex', mt: '15px', mb: '50px' }}>
        <Box sx={{ flex: '0 0 auto' }}>
          <div className={styles.desktop} style={{ height: '100%' }}>
            <Categories
              onSelectCategory={setSelectedCategory}
              item={item}
              closeDrawer={() => null}
            />
          </div>
        </Box>
        <Box sx={{ flex: '1 1 auto' }}>
          <CategoryTitle selectedCategory={item?.cat} />
          <Grid
            container
            spacing={{ xs: 1, sm: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {itemsData
              .filter(
                (tool) => !selectedCategory || tool.catId === selectedCategory
              )
              .map((tool) => {
                const price = +tool.price;
                const pvm = (price * 21) / 100;
                const priceWithVAT = price + pvm;

                return (
                  <Grid item xs={2} sm={4} md={4} key={tool.id}>
                    <Link
                      to={`/${tool.catId}/${tool.id}`}
                      className={styles.link}
                    >
                      <div className={styles.itemContainer}>
                        <div className={styles.image}>
                          <img src={tool.picture} alt={tool.name} />
                        </div>

                        <div className={styles.description}>
                          <h2>{tool.name}</h2>
                        </div>
                        <Divider sx={{ mb: 2 }} />
                        <div className={styles.itemPrice}>
                          <p className={styles.price}>
                            <span>{price} € </span>/1d (su PVM 21%:
                            {priceWithVAT.toFixed(2)} €)
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default ItemList;
