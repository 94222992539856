import { Container, Typography, Divider, Box } from '@mui/material';

function Conditions() {
  return (
    <Container>
      <Box
        sx={{
          padding: '70px 0',
          maxWidth: '900px',
          height: 'auto',
          margin: '0 auto',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ fontWeight: 'bold', color: '#213F5C', pb: 1 }}
        >
          Nuomos sąlygos
        </Typography>
        <Divider />
        <Typography sx={{ mt: 3, lineHeight: '1.8rem', color: '#5d656b' }}>
          <Typography variant="body1" sx={{ fontWeight: '600' }}>
            Nuomos laikotarpis ir mokėjimo sąlygos:
          </Typography>
          <Typography sx={{ mt: 1, lineHeight: '1.8rem', color: '#5d656b' }}>
            <ul>
              <li>
                Klientas turi teisę naudotis įrankiu(ais) nuo [pradžios datos]
                iki [pabaigos datos].
              </li>
              <li>
                Mokėjimas už nuomą turi būti atliktas iš anksto per [nustatytą
                laikotarpį] ir turi apimti visą nuomos mokesčio sumą.{' '}
              </li>
              <li>
                Papildomos išlaidos, tokios kaip pristatymo ar draudimo
                išlaidos, turi būti sumokėtos atskirai.
              </li>
            </ul>
          </Typography>
          <br />

          <Typography variant="body1" sx={{ fontWeight: '600' }}>
            Būklės ir naudojimo taisyklės:
          </Typography>
          <Typography sx={{ mt: 1, lineHeight: '1.8rem', color: '#5d656b' }}>
            <ul>
              <li>
                Klientas prisiima atsakomybę už įrankio(ų) būklę ir turi
                grąžinti juos tokius pat, kokie buvo gauti.
              </li>
              <li>
                Įrankis turi būti naudojamas tik pagal numatytas paskirtį ir
                saugumo taisykles, kurias nurodo gamintojas.
              </li>
              <li>
                Draudžiama atlikti bet kokias modifikacijas ar remontus be
                nuomos įmonės sutikimo.
              </li>
            </ul>
          </Typography>
          <br />

          <Typography variant="body1" sx={{ fontWeight: '600' }}>
            Grąžinimo tvarka:
          </Typography>
          <Typography sx={{ mt: 1, lineHeight: '1.8rem', color: '#5d656b' }}>
            <ul>
              <li>
                Įrankiai turi būti grąžinti nuomos įmonei iki [grąžinimo datos].
              </li>
              <li>
                Grąžinant įrankį, klientas privalo užtikrinti, kad jie būtų
                tinkamai supakuoti ir paruošti vežimui.
              </li>
            </ul>
          </Typography>

          <br />
          <Typography variant="body1" sx={{ fontWeight: '600' }}>
            Draudimai ir atsakomybės apribojimai:
          </Typography>
          <Typography sx={{ mt: 1, lineHeight: '1.8rem', color: '#5d656b' }}>
            <ul>
              <li>
                Draudžiama parduoti, perkelti arba subnuomoti įrankį(į)
                tretiesiems asmenims be nuomos įmonės raštiško sutikimo.
              </li>
              <li>
                Klientas prisiima visą atsakomybę už bet kokias žalos ar
                nuostolius, atsiradusius dėl netinkamo įrankio naudojimo.
              </li>
            </ul>
          </Typography>
          <br />
          <Typography variant="body1" sx={{ fontWeight: '600' }}>
            Papildomos sąlygos:
          </Typography>
          <Typography sx={{ mt: 1, lineHeight: '1.8rem', color: '#5d656b' }}>
            <ul>
              <li>
                Nuomojant brangesnius įrankius, gali būti reikalaujama sumokėti
                užstatą, kuris bus grąžintas klientui, kai įrankis bus grąžintas
                nesugadintas.
              </li>
              <li>
                Klientas privalo pranešti nuomos įmonei apie bet kokias
                sutrikimus ar problemų atsiradimą su įrankiu per [nurodytą
                laikotarpį].
              </li>
            </ul>
          </Typography>
        </Typography>
      </Box>
    </Container>
  );
}

export default Conditions;
