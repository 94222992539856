const AddressMap = () => {
  return (
    <div style={{ marginTop: '20px' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23247.026174810003!2d25.3827322!3d54.3137105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ddd0b004535b1f%3A0x82d2d3896c783799!2sJ.%20Sniadeckio%20g.%202%2C%2017109%20%C5%A0al%C4%8Dininkai%2C%20Lithuania!5e0!3m2!1sen!2sus!4v1649776713614!5m2!1sen!2sus"
        width="100%"
        height="450px"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </div>
  );
};
export default AddressMap;
